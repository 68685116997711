import React from "react";
import { IoIosAddCircle } from "react-icons/io";

type AddButtonProps = {
  buttonText: string;
  handleClick: () => void;
};

export const AddButton: React.FC<AddButtonProps> = ({
  buttonText,
  handleClick,
}) => {
  return (
    <button
      onClick={handleClick}
      className="w-fit flex items-center gap-1 bg-green-500 hover:bg-green-400 transition duration-150 ease-in-out rounded-md shadow-lg text-white p-2 focus:ring-green-700 focus:ring-1"
    >
      {buttonText}
      <IoIosAddCircle />
    </button>
  );
};
