import { useQuery, UseQueryResult } from "@tanstack/react-query";
import authorizedRequest from "../utils/authorizedRequest";
import type { QuoteRequest } from "../Types/types";

type QuoteRequestsData = {
  quoteRequests: QuoteRequest[];
  totalPages: number;
};

type UseQuoteRequestsResult = {
  data: QuoteRequestsData | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
};

// Fetches Quote Requests
export const useQuoteRequests = (
  page: number,
  limit: number
): UseQuoteRequestsResult => {
  const API_URL = process.env.REACT_APP_API_URL as string;

  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  }: UseQueryResult<QuoteRequestsData> = useQuery<QuoteRequestsData>({
    queryKey: ["quoterequests", page, limit],
    queryFn: async (): Promise<QuoteRequestsData> => {
      const response: any = await authorizedRequest(
        `${API_URL}/quoterequest/all/?page=${page || 1}&limit=${limit || 10}`
      );
      return response?.data;
    },
    refetchInterval: 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
