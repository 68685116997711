import React, { useState } from "react";
import type { Labor as LaborType } from "../../Types/types";
import { IoAddCircle } from "react-icons/io5";
import { LaborModal } from "./LaborModal";

type LaborProps = {
  labor: LaborType[];
  setLabor: React.Dispatch<React.SetStateAction<LaborType[]>>;
};

export const Labor: React.FC<LaborProps> = ({ labor, setLabor }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const deleteLabor = (index: number) => {
    setLabor(labor.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className="flex items-center gap-3 pb-4">
        <h1 className="text-grey-darker text-lg font-bold">Labor</h1>
        <button
          onClick={() => setModalIsOpen(true)}
          className="flex items-center gap-2 bg-green-400 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-150 ease-in-out"
        >
          <span>Add Labor</span>
          <IoAddCircle />
        </button>
      </div>
      {labor.length === 0 ? (
        <span className="text-left text-gray-400">Add labor cost</span>
      ) : (
        <table className="">
          <thead>
            <tr className="bg-blue-400 text-white font-semibold">
              <th className="p-3 rounded-l-full">Hourly Rate</th>
              <th className="p-3">Hours</th>
              <th className="p-3">Total Cost</th>
              <th className="p-3 rounded-r-full">{/*Delete*/}</th>
            </tr>
          </thead>
          <tbody>
            {labor.map((labor, index) => (
              <tr key={index} className="border-b">
                <td className="p-2 px-4 border-r-2">{labor.hourlyRate}</td>
                <td className="p-2 px-4 border-r-2">${labor.hours}</td>
                <td className="p-2 px-4 border-r-2">
                  ${labor.hourlyRate * labor.hours}
                </td>
                <td className="p-2 px-4">
                  <button
                    type="button"
                    className="text-red-600 border border-red-500 px-2 hover:bg-red-500 hover:text-white"
                    onClick={() => deleteLabor(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <LaborModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        setLabor={setLabor}
      />
    </>
  );
};
