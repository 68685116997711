import React from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { Disclosure } from "@headlessui/react";
import {
  FaSignOutAlt,
  FaRegCalendar,
  FaChartBar,
  FaArrowLeft,
  FaTasks,
} from "react-icons/fa";
import { AiTwotoneSchedule } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmployeeDashboard } from "./EmployeeDashboard";
import { ViewJobs } from "./ViewJobs";
import GoogleCalendar from "../AdminPage/GoogleCalendar";
import { SetSchedule } from "./SetSchedule";

export const EmployeePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("jwt"); //All we need to 'logout' is to remove jwt from local storage
    navigate("/login");
    toast.success("Successfully logged out!");
  };

  //GoogleCalendar EmbedURL
  const embedUrl =
    "https://calendar.google.com/calendar/embed?src=wizardsweb42%40gmail.com&ctz=America%2FLos_Angeles";

  // Function to determine the style of a tab based on its current selection status
  const getSelectedStyle = (tabName: string) => {
    const isSelected = location.pathname === tabName;
    if (isSelected) {
      return {
        container: "text-gray-500",
        icon: "text-gray-500",
        text: "text-gray-500",
      }; // Style for the selected tab
    } else {
      return {
        container: "",
        icon: "",
        text: "",
      }; // Default style for other tabs
    }
  };

  return (
    <div className="flex">
      {/* Sidebar section */}
      <aside className="h-screen">
        <Disclosure as="nav">
          <Disclosure.Button className="group peer absolute right-4 top-4 inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <GiHamburgerMenu
              className="block h-6 w-6 text-gray-500 md:hidden"
              aria-hidden="true"
            />
          </Disclosure.Button>
          {/* Container for the sidebar content */}
          <div className="peer:transition fixed -left-96 top-0 z-20 h-screen w-1/2 bg-gray-800 p-3 delay-150 duration-200 ease-out peer-focus:left-0 lg:left-0 lg:w-60">
            <div className="flex h-full flex-col justify-between">
              {/* Top section of the sidebar */}
              <div className="flex-1">
                {/* Header section with logo and sign-out button */}
                <div className="mb-6 flex items-center justify-between border-b border-gray-700 pb-4">
                  {/* Placeholder for the logo */}
                  <img
                    className="h-12 w-15 cursor-pointer"
                    src="/images/newbhslogotextless.png"
                    alt="Logo"
                    onClick={() => navigate("/")}
                  />
                  {/* Sign out button with icon */}
                  <button
                    onClick={handleLogout}
                    className="flex flex-none items-center justify-center rounded-full bg-white p-2 px-3 text-sm text-gray-800 transition-transform hover:scale-105"
                  >
                    Sign out <FaSignOutAlt className="ml-1" />
                  </button>
                </div>

                {/* Main navigation items */}
                <section className="my-4 border-b border-gray-700 pb-4">
                  {/* Each `div` here represents a navigation item with an icon and label */}

                  {/* Dashboard navigation item/Tab */}

                  <Link to="/employee">
                    <div
                      className={`group m-auto mb-2 flex cursor-pointer items-center justify-start gap-4 rounded-md p-2`}
                    >
                      <FaChartBar
                        className={`text-2xl text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee").icon
                        }`}
                      />
                      <h3
                        className={`text-base font-semibold text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee").text
                        }`}
                      >
                        Dashboard
                      </h3>
                    </div>
                  </Link>

                  {/* Calendar navigation item/Tab */}
                  <Link to="calendar">
                    <div
                      className={`group m-auto mb-2 flex cursor-pointer items-center justify-start gap-4 rounded-md p-2 ${
                        getSelectedStyle("/employee/calendar").container
                      }`}
                    >
                      <FaRegCalendar
                        className={`text-2xl text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee/calendar").icon
                        }`}
                      />
                      <h3
                        className={`text-base font-semibold text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee/calendar").text
                        }`}
                      >
                        Calendar
                      </h3>
                    </div>
                  </Link>

                  {/* Quote Requests navigation item/Tab */}
                  <Link to="jobs">
                    <div
                      className={`group m-auto mb-2 flex cursor-pointer items-center justify-start gap-4 rounded-md p-2 ${
                        getSelectedStyle("/employee/jobs").container
                      }`}
                    >
                      <FaTasks
                        className={`text-2xl text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee/jobs").icon
                        }`}
                      />
                      <h3
                        className={`text-base font-semibold text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee/jobs").text
                        }`}
                      >
                        Jobs
                      </h3>
                    </div>
                  </Link>

                  <Link to="set-schedule">
                    <div
                      className={`group m-auto mb-2 flex cursor-pointer items-center justify-start gap-4 rounded-md p-2 ${
                        getSelectedStyle("/employee/set-schedule").container
                      }`}
                    >
                      <AiTwotoneSchedule
                        className={`text-2xl text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee/set-schedule").icon
                        }`}
                      />
                      <h3
                        className={`text-base font-semibold text-gray-100 group-hover:text-gray-500 ${
                          getSelectedStyle("/employee/set-schedule").text
                        }`}
                      >
                        Set Schedule
                      </h3>
                    </div>
                  </Link>
                </section>
              </div>

              {/* Bottom section of the sidebar */}
              <section className="border-gray-100 pb-4">
                <a href="/">
                  <div className="group m-auto mb-2 flex cursor-pointer items-center justify-start gap-4 rounded-md p-2 hover:opacity-50">
                    <FaArrowLeft className="text-2xl text-red-700" />
                    <h3 className="text-base font-semibold text-gray-100">
                      Home Page
                    </h3>
                  </div>
                </a>
              </section>
            </div>
          </div>
        </Disclosure>
      </aside>
      {/* Main content area */}
      <main className="w-full flex-1 overflow-x-hidden md:pl-60">
        <Routes>
          <Route path="" element={<EmployeeDashboard />} />
          <Route path="jobs" element={<ViewJobs />} />
          <Route path="set-schedule" element={<SetSchedule />} />
          <Route
            path="calendar"
            element={
              <GoogleCalendar
                embedUrl={embedUrl}
                width="800px"
                height="600px"
              />
            }
          />
        </Routes>
      </main>
    </div>
  );
};
