import React, { useState } from "react";
import { IoAddCircle } from "react-icons/io5";
import { MaterialModal } from "./MaterialModal";
import { Material } from "../../Types/types";

type MaterialsProps = {
  materials: Material[];
  setMaterials: React.Dispatch<React.SetStateAction<Material[]>>;
};

export const Materials: React.FC<MaterialsProps> = ({
  materials,
  setMaterials,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const deleteMaterial = (index: number) => {
    setMaterials(materials.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className="flex items-center gap-3 pb-4">
        <h1 className="text-grey-darker text-lg font-bold">Materials</h1>
        <button
          onClick={() => setModalIsOpen(true)}
          className="flex items-center gap-2 bg-blue-400 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-150 ease-in-out"
        >
          <span>Add Materials</span>
          <IoAddCircle />
        </button>
      </div>
      {materials.length === 0 ? (
        <span className="text-left text-gray-400">Add an item...</span>
      ) : (
        <table className="">
          <thead>
            <tr className="bg-blue-400 text-white font-semibold">
              <th className="p-3 rounded-l-full">Item</th>
              <th className="p-3">Cost</th>
              <th className="p-3">Quantity</th>
              <th className="p-3">Subtotal</th>
              <th className="p-3 rounded-r-full">{/*Delete*/}</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index} className="border-b">
                <td className="p-2 px-4 border-r-2">{material.name}</td>
                <td className="p-2 px-4 border-r-2">${material.unitPrice}</td>
                <td className="p-2 px-4 border-r-2">{material.quantity}</td>
                <td className="p-2 px-4 border-r-2">
                  ${material.quantity * material.unitPrice}
                </td>
                <td className="p-2 px-4">
                  <button
                    type="button"
                    className="text-red-600 border border-red-500 px-2 hover:bg-red-500 hover:text-white"
                    onClick={() => deleteMaterial(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <MaterialModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        setMaterials={setMaterials}
      />
    </>
  );
};
