import React from "react";

import type { JobStatus as JobStatusType } from "../../../Types/types";
import Skeleton from "react-loading-skeleton";

type JobStatusProps = {
  isLoading: boolean;
  jobStatus: JobStatusType;
};

export const JobStatus: React.FC<JobStatusProps> = ({
  isLoading,
  jobStatus,
}) => {
  if (isLoading) {
    return (
      <Skeleton
        width={150}
        height={40}
        borderRadius={"2rem"}
        className="border"
      />
    );
  }
  return (
    <h1
      className={`${
        jobStatus === "Completed"
          ? "bg-green-500"
          : jobStatus === "Not Started"
          ? "bg-yellow-500"
          : jobStatus === "Cancelled"
          ? "bg-red-500"
          : jobStatus === "In Progress"
          ? "bg-blue-500"
          : ""
      } text-white p-2 text-lg border rounded-full`}
    >{`Status: ${jobStatus || ""}`}</h1>
  );
};
