/**
 * This is the template used for individual record pages, such as individual quote requests, individual jobs, etc...
 */
import React from "react";

type PageWrapperProps = {
  children: React.ReactNode;
};

export const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <div className="bg-[#f2f2f4] py-2">
      <div className="bg-white p-4 m-4 rounded-lg shadow-2xl">{children}</div>
    </div>
  );
};
