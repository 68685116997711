import { useState, useMemo } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import AddUserModal from "./AddUserModal";
import type { User } from "../../../Types/types";
import { TabLayout } from "../../../components/TabLayout";
import SearchBar from "../../../components/SearchBar";
import { UsersTable } from "./UsersTable";
import { ResultsCount } from "../../../components/ResultsCount";
import { useUsers } from "../../../hooks/useUsers";
import { AddButton } from "../../../components/AddButton";

const USERS_PER_PAGE = 10;

export const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const { data, isError, error, isLoading } = useUsers(
    currentPage,
    USERS_PER_PAGE
  );

  const totalPages = data?.totalPages || 0;

  const filteredUsers: User[] = useMemo(() => {
    const users = data?.users || [];

    if (!users || users.length === 0) return [];
    if (!searchTerm) return users;
    return users.filter(
      (user) =>
        user?.username?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user?.phone?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user?.fullName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [data?.users, searchTerm]);

  if (isError) {
    console.error("Error fetching users:", error);
    return <div>Error fetching users. Please try again later.</div>;
  }

  return (
    <TabLayout title="Users">
      <div className="flex items-center mb-4 gap-6">
        <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />

        <AddButton
          handleClick={() => setIsAddModalOpen(true)}
          buttonText="Add User"
        />
      </div>

      <ResultsCount results={filteredUsers?.length} isLoading={isLoading} />

      <UsersTable users={filteredUsers} isLoading={isLoading} />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
        isLoading={isLoading}
      />

      <AddUserModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </TabLayout>
  );
};
