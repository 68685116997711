import React from "react";

type TableRowProps = {
  children: React.ReactNode;
  className?: string; // Classes must be passed in as important (ex: instead of text-xl, use !text-xl)
};

export const TableRow: React.FC<TableRowProps> = ({
  className = "",
  children,
}) => {
  const tableRowClassName = `${className} hover:bg-yellow-50 transition ease-in-out`;

  return <tr className={tableRowClassName}>{children}</tr>;
};
