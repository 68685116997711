import { useQuery, UseQueryResult } from "@tanstack/react-query";
import authorizedRequest from "../utils/authorizedRequest";
import type { User } from "../Types/types";

type UserData = {
  users: User[];
  totalPages: number;
};

type UseUsersResult = {
  data: UserData | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
};

// Fetches users
export const useUsers = (page?: number, limit?: number): UseUsersResult => {
  const API_URL = process.env.REACT_APP_API_URL as string;

  const { data, isLoading, isError, error, refetch }: UseQueryResult<UserData> =
    useQuery<UserData>({
      queryKey: ["users", page, limit],
      queryFn: async (): Promise<UserData> => {
        const response: any = await authorizedRequest(
          `${API_URL}/users/?page=${page || 1}&limit=${limit || 10}`
        );
        return response?.data as UserData;
      },
      refetchInterval: 60 * 1000,
      staleTime: 5 * 60 * 1000,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
