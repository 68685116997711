import React from "react";

import { IoFilter } from "react-icons/io5";

type FilterButtonProps = {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonText: string;
};

export const FilterButton: React.FC<FilterButtonProps> = ({
  setIsDropdownOpen,
  buttonText,
}) => {
  return (
    <button
      onClick={() => setIsDropdownOpen((prev) => !prev)}
      className="flex gap-2 items-center bg-blue-400 hover:bg-blue-300 text-white rounded-3xl px-5 py-2 ml-4 transition duration-150 ease-in-out focus:ring-blue-500 focus:ring-1"
    >
      <span className="">{buttonText}</span> <IoFilter size="1.25em" />
    </button>
  );
};
