import { useState } from "react";
import PaddingSectionLayout from "../../layouts/PaddingSectionLayout";
import Modal from "../../components/UI/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  title?: string;
  content?: string;
}

const QuoteStatusInput = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [quoteRequestNumber, setQuoteRequestNumber] = useState("");
  const [quoteNumberError, setQuoteNumberError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const navigate = useNavigate();

  const handleSignInWithQuoteNumber = async () => {
    setQuoteNumberError(!quoteRequestNumber.trim());
    if (quoteRequestNumber.trim()) {
      try {
        await axios.get(`${API_URL}/quoterequest/status/${quoteRequestNumber}`);
        navigate(`/quote-request-status/${quoteRequestNumber}`);
      } catch (err: any) {
        setModal({
          content: "Invalid quote number.",
        });
      }
    } else {
      setModal({
        content: "Please enter your quote number.",
      });
    }
  };

  const errorHandler = () => {
    setModal(null);
  };

  return (
    <div className="">
      <PaddingSectionLayout>
        {modal && (
          <Modal
            title={modal.title}
            content={modal.content}
            onConfirm={errorHandler}
          />
        )}
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="p-4 text-4xl font-bold">
            Check The Status of Your Quote Request
          </h1>
          <p className="max-w-md p-5 text-xl">
            If you have a quote request number, you can check your quote request
            status here.
          </p>
          <div className="flex w-full flex-col gap-7 rounded-md bg-gray-200 p-4 md:max-w-lg md:p-12">
            <input
              placeholder="ex. Q00000000"
              value={quoteRequestNumber}
              onChange={(e) => setQuoteRequestNumber(e.target.value)}
              className={`w-full border ${
                quoteNumberError
                  ? "border-red-500 placeholder-red-500"
                  : "border-black placeholder-black"
              } rounded-xl bg-[#F2F2F4] p-1 md:p-2`}
            />
            <button
              className="rounded-xl bg-[#F69327] px-5 py-2 text-xs font-medium text-[#2D333A] shadow-md transition-transform hover:scale-105 md:text-lg"
              onClick={handleSignInWithQuoteNumber}
            >
              Check Quote Request Status
            </button>
            <div className="flex justify-center">
              <p className="mt-4 text-center">
                Haven't submitted a quote request?{" "}
                <a href="/get-a-quote" className="underline">
                  Submit one here!
                </a>
              </p>
            </div>
          </div>
        </section>
      </PaddingSectionLayout>
    </div>
  );
};

export default QuoteStatusInput;
