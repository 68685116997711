import React, { createContext, useContext } from "react";
import type { Job } from "../Types/types";

type JobContextType = {
  job: Job | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
  refetch: () => void;
};

const JobContext = createContext<JobContextType | undefined>(undefined);

export const useJobContext = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error("useJobContext must be used within a JobProvider");
  }
  return context;
};

export const JobProvider: React.FC<{
  job: Job | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
  refetch: () => void;
  children: React.ReactNode;
}> = ({ children, job, isLoading, isError, error, refetch }) => {
  return (
    <JobContext.Provider value={{ job, isLoading, isError, error, refetch }}>
      {children}
    </JobContext.Provider>
  );
};
