import axios from "axios";
import DOMPurify from "dompurify"; // Import DOMPurify
import { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../components/UI/Modal";
import PaddingSectionLayout from "../../layouts/PaddingSectionLayout";
import PageLayout from "../../layouts/PageLayout";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginRedirect } from "./LoginRedirect";

// Interface for modal properties
interface ModalProps {
  title?: string;
  content?: string;
}

const Login = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  // State for managing form inputs and errors
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate(); // Hook for navigation
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isTokenValid = async (token: string | null) => {
      if (!token) {
        return;
      }

      setIsLoading(true);

      try {
        const response = await axios.get(`${API_URL}/login/user-role`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const userRole = response?.data?.userRole;

        if (userRole === "admin") {
          navigate("/admin");
        } else if (userRole === "employee") {
          navigate("/employee");
        }
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
    };

    const token: string | null = localStorage.getItem("jwt");
    isTokenValid(token);
  }, []);

  const validateUsername = (username: string) => {
    return true;
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignInWithEmail = async () => {
    setLoginIsLoading(true);

    if (!username || !password) {
      setLoginIsLoading(false);
      return toast.warning("Username and password required");
    }

    const requestBody = {
      username,
      password,
    };

    try {
      const response = await axios.post(`${API_URL}/login`, requestBody);
      const { token, role } = response?.data;

      console.log("ROLE: ", role);

      if (token) {
        localStorage.setItem("jwt", token);
        toast.success("Login Successful");
        if (role === "admin") {
          navigate(`/admin`);
        } else if (role === "employee") {
          navigate(`/employee`);
        }
      } else {
        toast.error("Error receiving token, login failed");
      }
    } catch (error: any) {
      toast.warning(error?.response?.data?.message);
      console.log("ERROR: ", error);
    } finally {
      setLoginIsLoading(false);
    }
  };

  // Handle modal closure
  const errorHandler = () => {
    setModal(null);
  };

  return isLoading ? (
    <LoginRedirect />
  ) : (
    <div className="">
      <PaddingSectionLayout>
        {/* Modal for displaying error messages */}
        {modal && (
          <Modal
            title={modal.title}
            content={modal.content}
            onConfirm={errorHandler}
          />
        )}
        <section className="flex flex-col items-center justify-center text-center">
          {/* Sign-in form layout */}
          <h1 className="p-4 text-4xl font-bold">Admin Sign In</h1>
          <p className="max-w-md p-4 text-xl">
            Please sign in with your username.
          </p>
          <div className="flex flex-col w-full gap-4 p-5 bg-gray-200 rounded-md md:max-w-lg md:p-12 ">
            <h3 className="font-bold md:text-xl">Sign-in</h3>
            {/* Email input field */}
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`w-full border ${
                emailError ? "border-red-500" : "border-black"
              } rounded-xl bg-[#F2F2F4] p-1 md:p-2`}
            />
            {emailError && <p className="text-sm text-red-500">{emailError}</p>}
            {/* Password input field and visibility toggle */}
            <div className="relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`w-full border ${
                  passwordError ? "border-red-500" : "border-black"
                } rounded-xl bg-[#F2F2F4] p-1 md:p-2`}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                {passwordVisible ? (
                  <FiEyeOff className="w-5 h-5 text-gray-700" />
                ) : (
                  <FiEye className="w-5 h-5 text-gray-700" />
                )}
              </button>
            </div>
            {passwordError && (
              <p className="text-sm text-red-500">{passwordError}</p>
            )}

            {/* Forgot password link - */}
            {/* <div className="text-right ">
              <Link
                to="/forgot-password"
                className="text-sm text-blue-600 hover:underline"
              >
                Forgot password?
              </Link>
            </div> */}
            <div className="items-center ">
              {/* Sign-in button */}
              {loginIsLoading ? (
                <SyncLoader color="#0068B5" />
              ) : (
                <button
                  type="submit"
                  onClick={handleSignInWithEmail}
                  className="p-2 px-10 font-bold text-white transition-transform transform bg-orange-500 rounded-md hover:scale-105"
                >
                  Sign in
                </button>
              )}
            </div>
          </div>
        </section>
      </PaddingSectionLayout>
    </div>
  );
};

export default Login;
