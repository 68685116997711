import React from "react";
import type { QuoteInformation } from "../../Types/types";

type InformationFormProps = {
  quoteInformation: QuoteInformation | null;
  setQuoteInformation: React.Dispatch<
    React.SetStateAction<QuoteInformation | null>
  >;
};

export const InformationForm: React.FC<InformationFormProps> = ({
  quoteInformation,
  setQuoteInformation,
}) => {
  const initialFormData: QuoteInformation = {
    clientName: "",
    projectName: "",
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setQuoteInformation((prevQuoteInformation: any) => ({
      ...prevQuoteInformation,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col md:flex-row gap-6">
      <div className="flex flex-col">
        <label>Client Name</label>
        <input
          type="text"
          name="clientName"
          onChange={handleInputChange}
          required
          className="border border-gray-800 rounded-md p-2 shadow-lg max-w-[200px]"
          placeholder="Client Name"
        />
      </div>
      <div className="flex flex-col">
        <label>Project Name</label>
        <input
          type="text"
          name="projectName"
          onChange={handleInputChange}
          required
          className="border border-gray-800 rounded-md p-2 shadow-lg max-w-[200px]"
          placeholder="Project Name"
        />
      </div>
    </div>
  );
};
