import React from "react";
import type { User } from "../../../Types/types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { TableHead } from "../../../components/Table/TableHead";
import { TableHeader } from "../../../components/Table/TableHeader";
import { TableData } from "../../../components/Table/TableData";
import { TableRow } from "../../../components/Table/TableRow";

type UsersTableProps = {
  users: User[];
  isLoading: boolean;
};

export const UsersTable: React.FC<UsersTableProps> = ({ users, isLoading }) => {
  return (
    <table className="min-w-full mb-10">
      <TableHead>
        <tr>
          <TableHeader title={"Username"} />
          <TableHeader title={"Full Name"} />
          <TableHeader title={"Email"} />
          <TableHeader title={"Phone Number"} />
          <TableHeader title={"Role"} />
        </tr>
      </TableHead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={5}>
              <Skeleton count={10} height={25} />
            </td>
          </tr>
        ) : (
          <>
            {users.map((user, index) => {
              return (
                <TableRow
                  key={`${user?.username}-${index}`}
                  className="hover:bg-yellow-50 transition ease-in-out"
                >
                  <TableData>{user?.username}</TableData>
                  <TableData>{user?.fullName}</TableData>
                  <TableData>{user?.email}</TableData>
                  <TableData>{user?.phone}</TableData>
                  <TableData>{user?.role}</TableData>
                </TableRow>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};
