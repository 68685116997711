// @ts-nocheck
import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import CustomLightbox from "./CustomLightbox";

interface Image {
  src: string;
  width: number;
  height: number;
}

interface PhotoGalleryProps {
  images: string[];
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ images = [] }) => {
  const formattedImages = images.map((image, index) => ({
    original: image,
    thumbnail: image,
    originalClass: "object-cover w-full h-full",
    thumbnailClass: "object-cover w-full h-full",
    description: `Image ${index + 1}`,
  }));

  return (
    <div className="max-w-[50%]">
      <ImageGallery
        items={formattedImages}
        showPlayButton={false}
        showThumbnails={true}
        showNav={true}
        slideOnThumbnailOver={true}
        thumbnailPosition="bottom"
      />
    </div>
  );
};

export default PhotoGallery;
