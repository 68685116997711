import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormModal } from "../../../components/FormModal";
import type { User } from "../../../Types/types";

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

enum UserRole {
  Employee = "employee",
  Admin = "admin",
}

interface FormData {
  _id: string;
  username: string;
  password: string;
  fullName: string;
  email: string;
  phone: string;
  role: UserRole;
}

const AddUserModal: React.FC<AddUserModalProps> = ({ isOpen, onClose }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const initialFormData: FormData = {
    _id: "",
    username: "",
    password: "",
    fullName: "",
    email: "",
    phone: "",
    role: UserRole.Employee,
  };

  const formFields = [
    {
      name: "username",
      label: "Username",
      type: "text",
      placeholder: "Username",
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Password",
    },
    {
      name: "fullName",
      label: "Full Name",
      type: "text",
      placeholder: "Full Name",
    },
    { name: "email", label: "Email", type: "email", placeholder: "Email" },
    { name: "phone", label: "Phone Number", type: "tel", placeholder: "Phone" },
    {
      name: "role",
      label: "Role",
      type: "select",
      options: [
        { value: UserRole.Employee, label: UserRole.Employee },
        { value: UserRole.Admin, label: UserRole.Admin },
      ],
    },
  ];

  const addUserMutation = useMutation({
    mutationFn: async (user: User) => {
      return await authorizedRequest.post(`${API_URL}/users/adduser`, user);
    },
    onSuccess: () => {
      toast.success(`User added successfully`);
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: (error) => {
      toast.error("Error adding user. Please try again");
      console.error("Error adding user:", error);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleAdd = async (formData: FormData) => {
    setIsLoading(true);

    const newUser: User = {
      _id: formData._id,
      username: formData.username,
      password: formData.password,
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      role: formData.role,
    };

    addUserMutation.mutate(newUser);
  };

  if (!isOpen) return null;

  return (
    <FormModal<FormData>
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleAdd}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Add User"
      isLoading={isLoading}
    />
  );
};

export default AddUserModal;
