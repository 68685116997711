import React from "react";
import PageLayout from "../../layouts/PageLayout";
import Hero from "./Hero";
import Services from "./Services";
// import Testimonials from "../../components/Testimonials";
import CTA from "./CTA";
import Contact from "./Contact";
import InfoCards from "./InfoCards";

const Home = () => {
  //Makes sure that if we click services or testimonials while not on the home page, it goes to home page and scrolls to that section
  window.onload = function () {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      var element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  };

  return (
    <PageLayout>
      <Hero />
      <InfoCards />
      <Services />
      <CTA />
      <Contact />
    </PageLayout>
  );
};

export default Home;
