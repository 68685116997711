import React, { useState } from "react";
import type { Labor as LaborType } from "../../Types/types";
import { FormModal } from "../FormModal";

type LaborModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setLabor: React.Dispatch<React.SetStateAction<LaborType[]>>;
};

export const LaborModal: React.FC<LaborModalProps> = ({
  isOpen,
  onClose,
  setLabor,
}) => {
  const initialFormData: LaborType = {
    hourlyRate: 0,
    hours: 0,
  };

  const formFields = [
    {
      name: "hourlyRate",
      label: "Hourly Rate",
      type: "number",
      placeholder: "Hourly Rate",
    },
    { name: "hours", label: "Hours", type: "number", placeholder: "Hours" },
  ];

  const handleSubmit = (data: LaborType) => {
    setLabor((prev) => [...prev, data]);
  };

  return (
    <FormModal<LaborType>
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Add Labor"
    />
  );
};
