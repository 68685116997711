import React from "react";
import PaddingSectionLayout from "../../layouts/PaddingSectionLayout";

const Services = () => {
  const services = [
    "Commercial and Residential",
    "Pressure Washing",
    "Doors and Frames",
    "Casing, Trim, and Moulding",
    "Tile",
    "Painting",
    "Drywall",
    "Plumbing Fixtures",
    "Electrical Fixtures",
  ];

  return (
    <PaddingSectionLayout id="services-section">
      <h2 className="text-center text-3xl sm:text-5xl pt-7 pb-8 sm:pb-16 font-bold">
        Our Services
      </h2>
      <div className="p-4 sm:p-6 rounded-lg flex flex-col sm:flex-row md:items-center text-base lg:text-lg">
        <div className="flex-1 pr-0 sm:pr-8 pb-4 sm:pb-0">
          <p className="pb-4">
            We are a team of <strong> dedicated professionals </strong> with a
            passion for improving homes and
            <strong> enhancing the lives of homeowners. </strong>
          </p>
          <p className="">
            With years of experience in the construction industry, we aim to
            deliver <strong> top-notch services </strong> in small-scale home
            repair and improvement projects.
          </p>
        </div>
        <div
          aria-label="Services List"
          className="flex-1 bg-color p-4 sm:p-6 rounded-lg"
        >
          <ul className="columns-2">
            {services.map((service, index) => (
              <li className="flex " key={index}>
                <span className="text-green-500 pr-2">&#x2713;</span> {service}
              </li>
            ))}
            {/*Seperated to avoid styling with checkmark*/}
            <li className="flex">And so much more!</li>
          </ul>
        </div>
      </div>
      <style>
        {`
          .bg-color {
            background-color: #2D333A;
            color: #F2F2F4;
            padding: 20px; /* Adjust padding as needed */
          }
        `}
      </style>
    </PaddingSectionLayout>
  );
};

export default Services;
