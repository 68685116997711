import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { TableHead } from "../../../components/Table/TableHead";
import { TableHeader } from "../../../components/Table/TableHeader";
import { TableData } from "../../../components/Table/TableData";
import { TableRow } from "../../../components/Table/TableRow";
import type { Job } from "../../../Types/types";
import type { Address } from "../../../Types/types";
import { Link } from "react-router-dom";

const TOTAL_COLUMNS = 4;

type JobsTableProps = {
  jobs: Job[];
  isLoading: boolean;
};

function getAddressString(address: Address): string {
  return `${address.street}, ${address.city}, ${address.state}, ${address.zip}`;
}

export const JobsTable: React.FC<JobsTableProps> = ({ jobs, isLoading }) => {
  return (
    <table className="min-w-full mb-10">
      <TableHead>
        <tr>
          <TableHeader title={"Client Name"} />
          <TableHeader title={"Address"} />
          <TableHeader title={"Assigned To"} />
          <TableHeader title={"Action"} />
        </tr>
      </TableHead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={TOTAL_COLUMNS}>
              <Skeleton count={10} height={25} />
            </td>
          </tr>
        ) : (
          <>
            {jobs.map((job, index) => {
              return (
                <TableRow key={`${job?._id}`}>
                  <TableData>{job?.contactPerson?.name}</TableData>
                  <TableData>
                    {getAddressString(job?.address) || "Address not stored"}
                  </TableData>
                  <TableData>
                    {job?.assignedTo?.length
                      ? job.assignedTo.map((user, index) => (
                          <>
                            <span key={user._id}>{user.fullName}</span>
                            {index < (job?.assignedTo?.length ?? 0) - 1 && (
                              <span>, </span>
                            )}
                          </>
                        ))
                      : "Not assigned yet"}
                  </TableData>
                  <TableData>
                    <Link
                      to={`/admin/jobs/${job?._id}`}
                      className="text-black underline hover:text-blue-700"
                    >
                      View
                    </Link>
                  </TableData>
                </TableRow>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};
