import React from "react";
import { useJobContext } from "../../../providers/JobProvider";
import Skeleton from "react-loading-skeleton";

export const ClientInfo = () => {
  const { job, isLoading } = useJobContext();

  if (isLoading)
    return (
      <>
        <div className="flex gap-11 py-2">
          {/*Horizontal flexbox for name, phone address*/}
          <div>
            <p className="font-bold">Client Name:</p>
            <Skeleton width={90} />
          </div>

          <div>
            <p className="font-bold">Email:</p>
            <Skeleton width={90} />
          </div>

          <div>
            <p className="font-bold">Phone:</p>
            <Skeleton width={90} />
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="flex gap-11 py-2">
        {/*Horizontal flexbox for name, phone address*/}
        <div>
          <p className="font-bold">Client Name:</p>
          <p>{job?.contactPerson?.name || "No name provided"}</p>
        </div>

        <div>
          <p className="font-bold">Email:</p>
          <a
            href={`mailto:${job?.contactPerson?.email}`}
            className="text-blue-500 hover:text-blue-300"
          >
            {" "}
            {job?.contactPerson?.email || "No email provided"}
          </a>
        </div>

        <div>
          <p className="font-bold">Phone:</p>
          <p>{job?.contactPerson?.phone || "No phone number given"}</p>
        </div>
      </div>
    </>
  );
};
