import React, { useState } from "react";
import { SyncLoader } from "react-spinners";

interface GoogleCalendarProps {
  embedUrl: string;
  width?: string;
  height?: string;
}

const GoogleCalendar: React.FC<GoogleCalendarProps> = ({
  embedUrl,
  width = "800px",
  height = "600px",
}) => {
  const [isLoading, setIsLoading] = useState(true);
  if (!embedUrl) {
    return <div>Embed URL is missing</div>;
  }

  return (
    <div className="flex flex-col bg-[#f2f2f4] min-h-screen">
      <div className="bg-white h-[3vw]">
        <h2 className="text-2xl font-bold p-4 pl-10">Calendar</h2>
      </div>
      <div className="m-4 bg-white rounded-lg shadow-2xl p-6 h-[45vw] min-h-[45vw]">
        {isLoading && <SyncLoader color="#0068B5" className="pt-4 pl-2" />}
        <iframe
          src={
            "https://calendar.google.com/calendar/embed?src=wizardsweb42%40gmail.com&ctz=America%2FLos_Angeles"
          }
          style={{ border: "0", width: "100%", height: "100%" }}
          // width={1200}
          // height={600}
          frameBorder={0}
          scrolling="no"
          title="Google Calendar"
          onLoad={() => setIsLoading(false)}
        />
      </div>
    </div>
  );
};

export default GoogleCalendar;
