import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type ResultsCountProps = {
  results: number;
  isLoading: boolean;
};

export const ResultsCount: React.FC<ResultsCountProps> = ({
  results,
  isLoading,
}) => {
  if (isLoading) {
    return <Skeleton width={60} />;
  }

  return (
    <div className="mb-4">
      <span className="text-md font-semibold">{results || "0"} results</span>
    </div>
  );
};
