const QuoteAccepted = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-[60vh]">
      <h1 className="text-3xl font-bold text-gray-700 mb-4">Thank You!</h1>
      <p className="text-gray-500 mb-8">
        You have accepted the quote. We will reach out to schedule a time that
        works.
      </p>
    </div>
  );
};

export default QuoteAccepted;
