import React, { useState } from "react";
import { useJobContext } from "../../../providers/JobProvider";
import { AddButton } from "../../../components/AddButton";
import { AssignWorkersModal } from "./AssignWorkersModal";
import Skeleton from "react-loading-skeleton";
import { FiEdit } from "react-icons/fi";

export const AssignedWorker = () => {
  const { job, isLoading } = useJobContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <div className="flex items-center gap-2 pb-2">
        <h1 className="text-grey-darker text-lg font-main font-bold">
          Assigned Worker(s)
        </h1>
        {(job?.assignedTo?.length ?? 0) > 0 && (
          <FiEdit
            className="text-yellow-600 text-xl hover:cursor-pointer hover:text-yellow-500"
            title="Edit Assigned Workers"
            onClick={() => setModalIsOpen(true)}
          />
        )}
      </div>
      {isLoading ? (
        <Skeleton width={100} />
      ) : (
        <>
          {job?.assignedTo?.length ? (
            <>
              <span>
                {job?.assignedTo?.length > 0 &&
                  job?.assignedTo?.map((user, index) => (
                    <>
                      <span className="font-main" key={user._id}>
                        {user.fullName}
                      </span>
                      {index < (job?.assignedTo?.length ?? 0) - 1 && (
                        <span>, </span>
                      )}
                    </>
                  ))}
              </span>
            </>
          ) : (
            <AddButton
              buttonText="Assign"
              handleClick={() => {
                setModalIsOpen(true);
              }}
            />
          )}

          <AssignWorkersModal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
          />
        </>
      )}
    </>
  );
};
