import React from "react";
import { BackButton } from "../BackButton";

type MainContentWrapperProps = {
  children: React.ReactNode;
  backButtonLabel: string;
  backButtonLink: string;
};

export const MainContentWrapper: React.FC<MainContentWrapperProps> = ({
  children,
  backButtonLabel,
  backButtonLink,
}) => {
  return (
    <div className="pt-4">
      <div className="px-4 pb-4">
        <div className="bg-blue-400 w-full h-[40px]">
          <BackButton backTo={backButtonLabel} link={backButtonLink} />
        </div>

        <div className="bg-white px-8 flex flex-col shadow-xl rounded-b-2xl py-4">
          {children}
        </div>
      </div>
    </div>
  );
};
