import React from "react";

type PageButtonProps = {
  currentPage: number;
  page: number;
  onPageChange: (page: number) => void;
};

export const PageButton: React.FC<PageButtonProps> = ({
  currentPage,
  page,
  onPageChange,
}) => {
  return (
    <>
      <button
        onClick={() => onPageChange(page)}
        className={`px-3 py-1 mx-1 rounded ${
          currentPage === page
            ? "bg-blue-400 text-white"
            : "bg-white text-black"
        }`}
      >
        {page}
      </button>
    </>
  );
};
