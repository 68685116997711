import React from "react";

type TabLayoutProps = {
  title: string;
  children: React.ReactNode;
};

export const TabLayout: React.FC<TabLayoutProps> = ({ title, children }) => {
  return (
    <div className="min-h-screen flex flex-col bg-[#f2f2f4]">
      {/* Header */}
      <div className="bg-white">
        <h2 className="text-2xl font-bold p-4 pl-10">{title}</h2>
      </div>

      {/* Main Content Area */}
      <div className="m-4 bg-white rounded-lg shadow-2xl p-6">{children}</div>
    </div>
  );
};
