import { useQuery, UseQueryResult } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import type { Quote } from "../../../Types/types";

type UseQuoteResult = {
  data: Quote | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
};

// Fetches an individual quote
export const useFetchQuote = (quoteId: string): UseQuoteResult => {
  const API_URL = process.env.REACT_APP_API_URL as string;

  const { data, isLoading, isError, error, refetch }: UseQueryResult<Quote> =
    useQuery<Quote>({
      queryKey: ["quote", quoteId],
      queryFn: async (): Promise<Quote> => {
        const response: any = await authorizedRequest(
          `${API_URL}/quotes/${quoteId}`
        );
        return response?.data as Quote;
      },
      refetchInterval: 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!quoteId,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
