import React from "react";
import { useJobContext } from "../../../providers/JobProvider";
import Skeleton from "react-loading-skeleton";

export const AddressInfo: React.FC = () => {
  const { job, isLoading } = useJobContext();

  if (isLoading) {
    return (
      <div className="flex gap-11 py-2">
        <div>
          <p className="font-bold">Street:</p>
          <p>
            <Skeleton width={90} />
          </p>
        </div>

        <div>
          <p className="font-bold">City:</p>
          <p>
            <Skeleton width={90} />
          </p>
        </div>

        <div>
          <p className="font-bold">State:</p>
          <p>
            <Skeleton width={90} />
          </p>
        </div>

        <div>
          <p className="font-bold">Zip:</p>
          <p>
            <Skeleton width={90} />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-11 py-2">
      <div>
        <p className="font-bold">Street:</p>
        <p>{job?.address?.street || "No street provided"}</p>
      </div>

      <div>
        <p className="font-bold">City:</p>
        <p>{job?.address?.city || "No city provided"}</p>
      </div>

      <div>
        <p className="font-bold">State:</p>
        <p>{job?.address?.state || "No state provided"}</p>
      </div>

      <div>
        <p className="font-bold">Zip:</p>
        <p>{job?.address?.zip || "No zip code provided"}</p>
      </div>
    </div>
  );
};
