import React from "react";
import { useNavigate } from "react-router-dom";

export default function Unauthorized() {
  const navigate = useNavigate();

  const goHome = () => navigate("/");
  const contact = () => navigate("/contact");

  return (
    <div className="flex flex-col gap-8 items-center justify-center min-h-[60vh]">
      <h1 className="text-6xl font-bold">401</h1>
      <h2 className="text-2xl">Unauthorized</h2>
      <p className="">You do not have permission to view this page</p>
      <div className="flex gap-4">
        {" "}
        <button
          onClick={goHome}
          className="py-3 px-5 text-white bg-[#0082E4] hover:bg-blue-400 rounded rouned-lg shadow-lg transition duration-150 ease-in-out"
        >
          Go Home
        </button>
        <button
          onClick={contact}
          className="py-3 px-5 text-white bg-[#0082E4] hover:bg-blue-400 rounded rouned-lg shadow-lg transition duration-150 ease-in-out"
        >
          Contact
        </button>
      </div>
    </div>
  );
}
