import React from "react";

type ChangePageButtonProps = {
  handleClick: () => void;
  disabled: boolean;
  text: string;
};

export const ChangePageButton: React.FC<ChangePageButtonProps> = ({
  handleClick,
  disabled,
  text,
}) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className="px-3 py-1 mx-1 rounded bg-white text-black disabled:opacity-50"
    >
      {text}
    </button>
  );
};
