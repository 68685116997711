import React from "react";

type TableDataProps = {
  children: React.ReactNode;
  className?: string; // Classes must be passed in as important (ex: instead of text-xl, use !text-xl)
};

export const TableData: React.FC<TableDataProps> = ({
  children,
  className = "",
}) => {
  const tableDataClassName = `${className} px-4 py-2 border-b`;

  return <td className={tableDataClassName}>{children}</td>;
};
