import type { QuoteStatus } from "../../Types/types";

export function getStatusClass(quoteStatus: QuoteStatus): string {
  switch (quoteStatus) {
    case "Accepted":
      return "text-green-500";
    case "Declined":
      return "text-red-500";
    case "Pending":
      return "text-yellow-500";
    case "Quote Sent":
      return "text-blue-500";
    default:
      return "";
  }
}
