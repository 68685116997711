import React from "react";

type JobIdProps = {
  jobId: string;
};

export const JobId: React.FC<JobIdProps> = ({ jobId }) => {
  return (
    <div>
      {jobId && (
        <p className="bg-blue-400 text-xs text-white font-bold py-1 px-2 border border-black rounded-full">
          ID: {jobId}
        </p>
      )}
    </div>
  );
};
