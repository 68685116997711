import { useQuery, UseQueryResult } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import type { Job, User } from "../../../Types/types";

type UseSearchEmployeesResult = {
  data: User[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
};

// Searches for employees by name
export const useSearchEmployees = (
  searchTerm: string | null
): UseSearchEmployeesResult => {
  const API_URL = process.env.REACT_APP_API_URL as string;

  const { data, isLoading, isError, error, refetch }: UseQueryResult<User[]> =
    useQuery<User[]>({
      queryKey: ["searchWorkers", searchTerm],
      queryFn: async (): Promise<User[]> => {
        const response: any = await authorizedRequest(
          `${API_URL}/users/search-employees?fullName=${searchTerm}`
        );
        return response?.data as User[];
      },
      refetchInterval: 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!searchTerm,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
