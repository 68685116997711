import React from "react";
import type { QuoteRequest } from "../../../Types/types";
import PhotoGallery from "../../../components/Gallery";

type InfoSectionProps = {
  quoteRequest: QuoteRequest;
};

export const InfoSection: React.FC<InfoSectionProps> = ({ quoteRequest }) => {
  return (
    <div>
      <h1 className="text-grey-darker text-lg font-bold pb-4">Client Info</h1>
      <div className="flex gap-11 py-2">
        {/*Horizontal flexbox for name, phone address*/}
        <div>
          <p className="font-bold">Client Name:</p>
          <p>{quoteRequest?.contactPerson?.name || "No name provided"}</p>
        </div>

        <div>
          <p className="font-bold">Email:</p>
          <a
            href={`mailto:${quoteRequest?.contactPerson?.email}`}
            className="text-blue-500 hover:text-blue-300"
          >
            {" "}
            {quoteRequest?.contactPerson?.email}
          </a>
        </div>

        <div>
          <p className="font-bold">Phone:</p>
          <p>{quoteRequest?.contactPerson?.phone || "No phone number given"}</p>
        </div>
      </div>

      <div className="flex gap-11 py-2">
        <div>
          <p className="font-bold">Street:</p>
          <p>{quoteRequest?.address?.street || "No street provided"}</p>
        </div>

        <div>
          <p className="font-bold">City:</p>
          <p>{quoteRequest?.address?.city || "No city provided"}</p>
        </div>

        <div>
          <p className="font-bold">State:</p>
          <p>{quoteRequest?.address?.state || "No state provided"}</p>
        </div>

        <div>
          <p className="font-bold">Zip:</p>
          <p>{quoteRequest?.address?.zip || "No zip code provided"}</p>
        </div>
      </div>

      <p className="font-bold">Job Description:</p>
      <p>{quoteRequest?.notes || "No description supplied"}</p>
      <div className="pb-2">
        <p className="font-bold">Preferred End Date:</p>
        <p>
          {quoteRequest?.preferredEndDate
            ? quoteRequest.preferredEndDate.toString().split("T")[0]
            : "No preferred end date specified"}
        </p>
      </div>
      <div className="pb-2">
        {quoteRequest?.images?.length > 0 ? (
          <div className="container">
            <h1 className="text-2xl font-bold mb-4">Images</h1>
            <PhotoGallery images={quoteRequest?.images} />
          </div>
        ) : (
          <h1>No images provided</h1>
        )}
      </div>
    </div>
  );
};
