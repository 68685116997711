import React, { useEffect, useState } from "react";
import QuotePreview from "../../../components/QuoteBuilder/QuotePreview";
import type { Quote } from "../../../Types/types";
import authorizedRequest from "../../../utils/authorizedRequest";

type SentQuoteProps = {
  quoteRequestId: string;
};

export const SentQuote: React.FC<SentQuoteProps> = ({ quoteRequestId }) => {
  const initialQuote: Quote = {
    _id: "",
    materials: [],
    labor: [],
    status: "Pending",
    quoteDate: new Date(),
    quoteRequestId: quoteRequestId,
    projectName: "",
    clientName: "",
    token: "",
    quoteId: "",
  };

  const [quote, setQuote] = useState<Quote>(initialQuote);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const quoteResponse = await authorizedRequest(
          `/quotes/by-quote-request/${quoteRequestId}`
        );

        setQuote(quoteResponse?.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };

    fetchQuote();
  }, [quoteRequestId]);

  useEffect(() => {
    console.log("QUOTE: ", quote);
  }, [quote]);

  return (
    <>
      <div className="flex gap-6 items-center pb-3">
        <h1 className="text-3xl">Sent Quote</h1>
        <h1
          className={`${
            quote?.status === "Accepted"
              ? "bg-green-500"
              : quote?.status === "Pending"
              ? "bg-yellow-400"
              : quote?.status === "Declined"
              ? "bg-red-500"
              : quote?.status === "Requested More Info"
              ? "bg-blue-500"
              : ""
          } text-white p-2 text-lg rounded-lg inline-block`}
        >{`${
          quote?.status === "Pending"
            ? "Awaiting Client Response"
            : quote?.status === "Accepted"
            ? "Accepted By Client"
            : quote?.status === "Declined"
            ? "Declined By Client"
            : quote?.status || ""
        }`}</h1>
      </div>
      <QuotePreview
        materials={quote?.materials}
        labor={quote?.labor}
        quoteId={quote?.quoteId}
        quoteInformation={{
          clientName: quote.clientName,
          projectName: quote.projectName,
        }}
      />
    </>
  );
};
