import React, { useMemo, useState } from "react";
import { TabLayout } from "../../../components/TabLayout";
import { useFetchJobs } from "../../../hooks/data/jobs/useFetchJobs";
import { JobsTable } from "./JobsTable";
import Pagination from "../../../components/Pagination/Pagination";
import SearchBar from "../../../components/SearchBar";
import type { Job } from "../../../Types/types";
import { ResultsCount } from "../../../components/ResultsCount";

const JOBS_PER_PAGE = 10;

export const AdminJobs = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useFetchJobs(currentPage, JOBS_PER_PAGE);

  const totalPages = data?.totalPages || 0;

  const filteredJobs: Job[] = useMemo(() => {
    const jobs = data?.jobs || [];

    if (!jobs || jobs.length === 0) return [];
    if (!searchTerm) return jobs;
    return jobs.filter((job) =>
      job?.contactPerson?.name
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase())
    );
  }, [searchTerm, data?.jobs]);

  return (
    <>
      <TabLayout title="Jobs">
        <div className="mb-4">
          <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
        </div>
        <ResultsCount results={filteredJobs?.length} isLoading={isLoading} />
        <JobsTable jobs={filteredJobs} isLoading={isLoading} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
          isLoading={isLoading}
        />
      </TabLayout>
    </>
  );
};
