import type { Costs } from "../Types/types";
import type { Material } from "../Types/types";
import type { Labor } from "../Types/types";

const TAX_RATE = 0.08;

export function getCosts(materials: Material[], labor: Labor[]): Costs {
  const materialsCost = materials.reduce((acc, material) => {
    return acc + material.unitPrice * material.quantity;
  }, 0);

  const laborCost = labor.reduce((acc, labor) => {
    return acc + labor.hourlyRate * labor.hours;
  }, 0);

  const totalCostBeforeTax = materialsCost + laborCost;

  const taxCost = parseFloat((totalCostBeforeTax * TAX_RATE).toFixed(2));

  const totalCostAfterTax = parseFloat(
    (totalCostBeforeTax + taxCost).toFixed(3)
  );

  return {
    totalCostBeforeTax,
    taxCost,
    totalCostAfterTax,
  };
}
