import React, { useRef, useEffect, MutableRefObject } from "react";

type UseOutsideClickProps = {
  callback: () => void;
};

export const useOutsideClick = (
  callback: UseOutsideClickProps["callback"]
): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback]);

  return ref;
};
