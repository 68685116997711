import React from "react";
import { User } from "../../../Types/types";
import Skeleton from "react-loading-skeleton";

type EmployeeDropdownProps = {
  employees: User[] | undefined;
  onEmployeeSelect: (employee: User) => void;
  isLoading: boolean;
};

export const EmployeeDropdown: React.FC<EmployeeDropdownProps> = ({
  employees,
  onEmployeeSelect,
  isLoading,
}) => {
  return (
    <div className="absolute w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-50 max-h-60 overflow-y-auto">
      {isLoading ? (
        <Skeleton width="90%" className="ml-2" />
      ) : (
        <>
          {employees && employees.length > 0 ? (
            employees.map((employee: User) => (
              <div
                key={employee._id}
                onClick={() => onEmployeeSelect(employee)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              >
                {employee.fullName}
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500">No employees found</div>
          )}
        </>
      )}
    </div>
  );
};
