import React from "react";
import { AddressInfo } from "./AddressInfo";
import { ClientInfo } from "./ClientInfo";

export const InfoSection = () => {
  return (
    <>
      <h1 className="text-grey-darker text-lg font-bold pb-2">Client Info</h1>
      <ClientInfo />
      <hr className="my-4" />
      <h1 className="text-grey-darker text-lg font-bold pb-2">
        Client Address
      </h1>
      <AddressInfo />
    </>
  );
};
