import React from "react";
import { useOutsideClick } from "../hooks/useOutsideClick";

type ModalLayoutProps = {
  children: React.ReactNode;
  onOutsideClick: () => void;
};

export const ModalLayout: React.FC<ModalLayoutProps> = ({
  children,
  onOutsideClick,
}) => {
  const outsideClickRef = useOutsideClick(onOutsideClick);

  return (
    <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div ref={outsideClickRef} className="bg-white p-4 rounded-lg shadow-lg">
        {children}
      </div>
    </div>
  );
};
