import React, { useState, useEffect } from "react";
import FullSectionLayout from "../layouts/FullSectionLayout";
import { IoIosHome } from "react-icons/io";

const Navbar = ({ isHomePage }: any) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navLinks = [
    {
      href: "/#services-section",
      label: "Services",
      className:
        "text-xl sm:text-lg hover:text-[#0082E4] transition duration-300 transition-transform transform hover:scale-110",
    },
    // {
    //   href: "/#testimonials-section",
    //   label: "Testimonials",
    //   className:
    //     "text-xl sm:text-lg hover:text-[#0082E4] transition duration-300 transition-transform transform hover:scale-110",
    // },
    {
      href: "/get-a-quote",
      label: "Get A Quote",
      className:
        "text-xl sm:text-lg hover:text-[#0082E4] transition duration-300 transition-transform transform hover:scale-110",
    },
    {
      href: "/contact",
      label: "Contact",
      className:
        "text-xl sm:text-lg hover:text-[#0082E4] transition duration-300 transition-transform transform hover:scale-110",
    },
    {
      href: "/quote-request-status",
      label: "Quote Request Status",
      className:
        "text-xl sm:text-lg hover:text-[#0082E4] transition duration-300 transition-transform transform hover:scale-110",
    },
  ];

  // const navButtons = [

  // ];

  const mobileNavButtons = [
    {
      href: "/get-a-quote",
      label: "Get A Quote",
      className:
        "bg-orange-500 text-black shadow-custom-shadow text-xs px-4 py-1 font-bold transition-transform transform hover:scale-105",
    },
    {
      href: "/contact",
      label: "Contact",
      className:
        "bg-white text-black shadow-custom-shadow text-xs px-4 py-1 font-bold transition-transform transform hover:scale-105",
    },
    {
      href: "/QuoteStatus",
      label: "Quote Status",
      className:
        "bg-custom-gray text-white shadow-custom-shadow text-xs px-4 py-1 font-bold transition-transform transform hover:scale-105",
    },
  ];

  const DefaultNavbar = () => {
    return (
      <div
        className={
          isHomePage
            ? `bg-gray-600/80 absolute z-20 w-full`
            : `bg-gray-600 w-full`
        }
      >
        <div className="container mx-auto flex items-center justify-between p-5 text-white">
          <a key={"logolink"} href="/" className="">
            <img
              width="70%"
              height="70%"
              key="bgslogo"
              src="/images/newbhslogotextless.png"
              alt="BHS LOGO"
            />
          </a>
          {navLinks.map((link) => (
            <a key={link.href} href={link.href} className={link.className}>
              {link.label}
            </a>
          ))}
          {/* 
          {navButtons.map((button) => (
            <a
              key={button.href}
              href={button.href}
              className={button.className}
            >
              {button.label}
            </a>
          ))} */}
        </div>
      </div>
    );
  };

  const MobileNavbar = () => {
    return (
      <div className="bg-custom-gray">
        <div className="container mx-auto flex items-center justify-between p-5 text-white">
          <a
            href="/"
            className="transform transition-transform duration-300 hover:scale-110 hover:text-blue-600"
          >
            <IoIosHome style={{ fontSize: "30px" }} />
          </a>
          {mobileNavButtons.map((button) => (
            <a
              key={button.href}
              href={button.href}
              className={button.className}
            >
              {button.label}
            </a>
          ))}
        </div>
      </div>
    );
  };

  return (
    <FullSectionLayout>
      {isMobile ? <MobileNavbar /> : <DefaultNavbar />}
    </FullSectionLayout>
  );
};

export default Navbar;
