import React from "react";
import { QuoteRequest } from "../../../Types/types";
import { TableHead } from "../../../components/Table/TableHead";
import { TableHeader } from "../../../components/Table/TableHeader";
import { TableData } from "../../../components/Table/TableData";
import { TableRow } from "../../../components/Table/TableRow";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

type QuoteRequestsTableProps = {
  quoteRequests: QuoteRequest[];
  isLoading: boolean;
};

const shortenId = (id: string) => {
  return id.length > 9 ? id.slice(-9) : id;
};

export const QuoteRequestsTable: React.FC<QuoteRequestsTableProps> = ({
  quoteRequests,
  isLoading,
}) => {
  return (
    <table className="w-full mb-10">
      <TableHead>
        <tr>
          <TableHeader title="Id" />
          <TableHeader title="Client Name" />
          <TableHeader title="Preferred End Date" />
          <TableHeader title="Status" />
          <TableHeader title="Action" />
        </tr>
      </TableHead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={5}>
              <Skeleton count={10} height={25} />
            </td>
          </tr>
        ) : (
          <>
            {quoteRequests.map((quoteRequest) => {
              const shortenedId = shortenId(quoteRequest._id);
              return (
                <TableRow key={quoteRequest._id}>
                  <TableData className="!font-bold">{`Q${shortenedId}`}</TableData>
                  <TableData>{quoteRequest?.contactPerson?.name}</TableData>
                  <TableData>
                    {quoteRequest?.preferredEndDate
                      ? new Date(
                          quoteRequest?.preferredEndDate
                        ).toLocaleDateString()
                      : "No Date Provided"}
                  </TableData>
                  <TableData>
                    {quoteRequest?.quoteStatus || "Unknown"}
                  </TableData>
                  <TableData>
                    <Link
                      to={`/admin/quote-requests/${quoteRequest?._id}`}
                      className="text-black underline hover:text-blue-700"
                    >
                      View
                    </Link>
                  </TableData>
                </TableRow>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};
