import { useQuery, UseQueryResult } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import type { Job } from "../../../Types/types";

type JobData = {
  jobs: Job[];
  totalPages: number;
};

type UseJobsResult = {
  data: JobData | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
};

// Fetches jobs
export const useFetchJobs = (page?: number, limit?: number): UseJobsResult => {
  const API_URL = process.env.REACT_APP_API_URL as string;

  const { data, isLoading, isError, error, refetch }: UseQueryResult<JobData> =
    useQuery<JobData>({
      queryKey: ["jobs", page, limit],
      queryFn: async (): Promise<JobData> => {
        const response: any = await authorizedRequest(
          `${API_URL}/jobs/?page=${page || 1}&limit=${limit || 10}`
        );
        return response?.data as JobData;
      },
      refetchInterval: 60 * 1000,
      staleTime: 5 * 60 * 1000,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
