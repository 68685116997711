import React from "react";
import { useNavigate } from "react-router-dom";

type BackButtonProps = {
  backTo: string;
  link: string;
};

export const BackButton: React.FC<BackButtonProps> = ({ backTo, link }) => {
  const navigate = useNavigate();

  return (
    <button
      className="text-white hover:text-gray-500 rounded-t-2xl text-left py-2 px-2 transition ease-in-out click:bg-gray-900"
      onClick={() => navigate(link)}
    >
      &lt; {`Back to ${backTo}`}
    </button>
  );
};
