import React from "react";
import { ModalLayout } from "../../../components/ModalLayout";

interface AcceptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const AcceptModal: React.FC<AcceptModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <ModalLayout onOutsideClick={onClose}>
      <h2 className="text-lg font-bold">Confirm Accept</h2>
      <p className="text-sm max-w-xs">
        Are you sure you want to accept this quote request? An email will be
        sent to the client letting them know you've accepted, and you will be
        given the option to build a quote.
      </p>
      <div className="flex justify-end mt-4 space-x-2">
        <button
          className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Accept
        </button>
      </div>
    </ModalLayout>
  );
};

export default AcceptModal;
