import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getStatusClass } from "./getStatusClass";
import type { QuoteStatus } from "../../Types/types";

const QuoteRequestStatus = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { quoteRequestId } = useParams();

  const [quoteStatus, setQuoteStatus] = useState<QuoteStatus>("Pending");

  useEffect(() => {
    async function FetchQuote() {
      try {
        const response = await axios.get(
          `${API_URL}/quoterequest/status/${quoteRequestId}`
        );

        setQuoteStatus(response.data.quoteStatus);

        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    }
    FetchQuote();
  }, [quoteRequestId]);

  return (
    <div className="px-10 py-10">
      <h1 className="font-bold text-3xl mb-4 break-words">{`Quote Request #${quoteRequestId}`}</h1>
      <div className="text-gray-700 mb-6 text-xs font-bold">
        <p>
          Please reach out to Bertoni's Handyman Services for any questions.
        </p>
      </div>
      <div
        className="px-10 py-10 bg-white-200 rounded-lg inline-block"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      >
        <h2 className="font-bold text-2xl">Quote Request Status</h2>
        <div className={`${getStatusClass(quoteStatus)} text-xl`}>
          {quoteStatus}
        </div>
      </div>
      {quoteStatus === "Declined" && (
        <>
          <p className="pt-6 italic">
            Please check email for more info, or{" "}
            <a
              href="/contact"
              className="text-blue-500 hover:text-blue-300 underline"
            >
              Contact Us
            </a>{" "}
            us if you think this is an error
          </p>
        </>
      )}
    </div>
  );
};

export default QuoteRequestStatus;
