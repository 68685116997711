import React, { useState, useEffect } from "react";
import FullSectionLayout from "../../layouts/FullSectionLayout";

import { Link } from "react-router-dom";

const PreloadImages = ({ images }: any) => {
  return (
    <div style={{ display: "none" }}>
      {images.map((image: any, index: any) => (
        <img key={index} src={image} alt="Preloaded" />
      ))}
    </div>
  );
};

const Hero = () => {
  const [bgIndex, setBgIndex] = useState(0);
  const images = [
    "/images/hero_bg3.webp",
    "/images/hero_bg4.webp",
    "/images/hero_bg.webp",
    "/images/hero_bg2.webp",
    "/images/hero_bg5.webp",
  ];

  const heroStyle = {
    backgroundImage: `url(${images[bgIndex]})`,
    height: "90vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 2s ease-in-out",
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBgIndex((current) => (current + 1) % images.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <FullSectionLayout>
      <PreloadImages images={images} />
      <div
        role="region"
        aria-label="Hero section"
        className="hero-container bg-black opacity-90 flex justify-start items-center p-4 sm:p-8 md:p-12 lg:p-16 min-h-screen"
        style={heroStyle}
      >
        <div className="content-container ml-4 sm:ml-8 md:ml-12 lg:ml-16 sm:mt-5 md:mt-8 lg:mt-40">
          <h1 className="text-white text-3xl sm:text-4xl md:text-4xl lg:text-6xl font-bold">
            Bertoni Handyman
          </h1>
          <h2 className="text-white text-3xl sm:text-4xl md:text-4xl lg:text-6xl font-bold">
            Services
          </h2>
          <p className="text-white text-xl sm:text-2xl md:text-2xl lg:text-3xl pt-4 sm:pt-6 md:pt-8 lg:pt-14">
            Your best choice for residential and commercial repairs, installs,
            and pressure washing
          </p>
          <div className="button-container flex flex-wrap mt-8 sm:mt-10 md:mt-12 lg:mt-16">
            <Link to="/get-a-quote">
              <button
                aria-label="Get a quote for Bertoni's Handyman services"
                className="bg-[#0082E4] text-black text-xl sm:text-xl md:text-xl lg:text-2xl px-4 sm:px-5 md:px-6 lg:px-7 py-3 sm:py-3 md:py-4 lg:py-5 font-bold mr-4 sm:mr-6 md:mr-8 lg:mr-10 focus:outline-none focus:ring-2 focus:ring-orange-400 hover:scale-105 shadow-custom-shadow transition-transform"
              >
                Get a Quote
              </button>
            </Link>

            <Link to="/contact">
              <button
                aria-label="Contact Bertoni's Handyman services"
                className="bg-white text-black text-xl sm:text-xl md:text-xl lg:text-2xl px-6 sm:px-7 md:px-8 lg:px-11 py-3 sm:py-3 md:py-4 lg:py-5 font-bold focus:outline-none focus:ring-2 focus:ring-black hover:scale-105 shadow-custom-shadow transition-transform"
              >
                Contact
              </button>
            </Link>
          </div>
        </div>
      </div>
    </FullSectionLayout>
  );
};

export default Hero;
