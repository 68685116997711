const QuoteDeclined = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-[60vh] bg-gray-50">
      <h1 className="text-3xl font-bold text-gray-700 mb-4">
        Successfully declined
      </h1>
      <p className="text-gray-500 mb-8">You have declined the quote.</p>
    </div>
  );
};

export default QuoteDeclined;
