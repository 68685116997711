import React from "react";
import Skeleton from "react-loading-skeleton";
import { ChangePageButton } from "./ChangePageButton";
import { PageButton } from "./PageButton";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  isLoading: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  isLoading,
}) => {
  const handleClick = (page: number) => {
    onPageChange(page);
  };

  const pageWindowSize = 5;
  const startPage = Math.max(1, currentPage - Math.floor(pageWindowSize / 2));
  const endPage = Math.min(totalPages, startPage + pageWindowSize - 1);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PageButton
          key={i}
          onPageChange={() => handleClick(i)}
          page={i}
          currentPage={currentPage}
        />
      );
    }
    return pageNumbers;
  };

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Skeleton width={100} height={30} />
      </div>
    );
  }

  return (
    <div className="flex justify-center mt-4">
      <ChangePageButton
        handleClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        text="Previous"
      />

      {/* Render first page if we have more than one page */}
      {startPage > 1 && (
        <>
          <PageButton
            onPageChange={() => handleClick(1)}
            page={1}
            currentPage={currentPage}
          />
          {startPage > 2 && <span className="px-3 py-1">...</span>}
        </>
      )}

      {renderPageNumbers()}

      {/* Render ellipsis and last page button if endPage is less than totalPages */}
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span className="px-3 py-1">...</span>}
          <PageButton
            onPageChange={() => handleClick(totalPages)}
            page={totalPages}
            currentPage={currentPage}
          />
        </>
      )}

      <ChangePageButton
        handleClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        text="Next"
      />
    </div>
  );
};

export default Pagination;
