import React, { useState } from "react";
import { ModalLayout } from "../../../components/ModalLayout";

interface DeclineModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
}

const DeclineModal: React.FC<DeclineModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [reasonForDecline, setReasonForDecline] = useState("");

  if (!isOpen) return null;

  return (
    <ModalLayout onOutsideClick={onClose}>
      <h2 className="text-lg font-bold">Confirm Decline</h2>
      <p className="text-sm max-w-xs pb-2">
        What is the reason for declining this quote request?
      </p>
      <textarea
        className="block p-2.5 min-w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(e) => setReasonForDecline(e.target.value)}
        id="declineQuote"
        name="declineQuote"
        rows={1}
        cols={50}
      />
      <div className="flex justify-end mt-4 space-x-2">
        <button
          className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={() => {
            onConfirm(reasonForDecline);
            onClose();
          }}
        >
          Decline
        </button>
      </div>
    </ModalLayout>
  );
};

export default DeclineModal;
