import React, { useState, useEffect, useMemo } from "react";
import { IoSearch } from "react-icons/io5";
import { debounce } from "lodash";

interface SearchBarProps {
  searchTerm: string;
  onSearchChange: (term: string) => void;
  debounceTime?: number;
  placeholder?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  onSearchChange,
  debounceTime = 300,
  placeholder = "Search...",
}) => {
  const [inputValue, setInputValue] = useState(searchTerm);

  useEffect(() => {
    setInputValue(searchTerm);
  }, [searchTerm]);

  const debouncedSearch = useMemo(
    () =>
      debounce((term: string) => {
        onSearchChange(term);
      }, debounceTime),
    [onSearchChange]
  );

  // Update debounced search when inputValue changes
  useEffect(() => {
    debouncedSearch(inputValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, debouncedSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="flex items-center border border-gray-300 rounded-3xl overflow-hidden w-fit">
      <input
        className="pl-5 pr-3 py-2 text-lg focus:outline-none"
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
      />
      <IoSearch className="mx-2 text-gray-500" />
    </div>
  );
};

export default SearchBar;
