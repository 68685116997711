import React from "react";
import type { QuoteRequest } from "../../../Types/types";

type HeaderProps = {
  quoteRequest: QuoteRequest;
};

export const Header: React.FC<HeaderProps> = ({ quoteRequest }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex justify-start items-center">
        <h1 className="text-3xl font-bold pr-2">Quote Request</h1>
        {quoteRequest?._id && (
          <p className="bg-blue-400 text-xs text-white font-bold py-1 px-2 border border-black rounded-full">
            ID: {quoteRequest?._id}
          </p>
        )}
      </div>
      <h1
        className={`${
          quoteRequest?.quoteStatus === "Accepted"
            ? "bg-green-500"
            : quoteRequest?.quoteStatus === "Pending"
            ? "bg-yellow-500"
            : quoteRequest?.quoteStatus === "Declined"
            ? "bg-red-500"
            : quoteRequest?.quoteStatus === "Quote Sent"
            ? "bg-blue-500"
            : ""
        } text-white p-2 text-lg border rounded-full`}
      >{`Status: ${quoteRequest?.quoteStatus || ""}`}</h1>
    </div>
  );
};
