import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";

interface ProtectedRouteProps {
  children: React.ReactNode;
  permittedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  permittedRoles = [],
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
  const [userRole, setUserRole] = useState<"employee" | "admin" | null>(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem("jwt");

      if (!token) {
        setTokenIsValid(false);
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/login/user-role`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200 && response.data.valid) {
          setTokenIsValid(true);
          setUserRole(response?.data?.userRole);
        } else {
          setTokenIsValid(false);
        }
      } catch (error) {
        console.error("Token validation error:", error);
        setTokenIsValid(false);
      }

      setIsLoading(false);
    };

    validateToken();
  }, [location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!tokenIsValid) {
    console.log("TOKEN INVALID");
    return <Navigate to="/login" replace />;
  }

  //@ts-ignore
  if (!permittedRoles.includes(userRole)) {
    console.log("USER ROLE: " + userRole);
    console.log("ROLE INVALID");
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
