import React, { useState } from "react";
import { SyncLoader } from "react-spinners";
import { FaCheck } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { QuoteRequest } from "../../../Types/types";
import authorizedRequest from "../../../utils/authorizedRequest";
import { toast } from "react-toastify";
import AcceptModal from "./AcceptModal";
import DeclineModal from "./DeclineModal";

type ActionButtonsProps = {
  quoteRequest: QuoteRequest;
  setQuoteRequest: React.Dispatch<React.SetStateAction<QuoteRequest>>;
};

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  quoteRequest,
  setQuoteRequest,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [acceptIsLoading, setAcceptIsLoading] = useState(false);
  const [declineIsLoading, setDeclineIsLoading] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);

  const handleAcceptQuote = async () => {
    try {
      setAcceptIsLoading(true);
      await authorizedRequest.put(
        `/quoterequest/markAccepted/${quoteRequest?._id}`,
        {}
      );

      setQuoteRequest((prev: QuoteRequest) => {
        return {
          ...prev,
          quoteStatus: "Accepted",
        };
      });

      toast.success("Quote accepted successfully");
    } catch (error) {
      toast.error("Error accepting quote");
      console.error("Failed to accept quote:", error);
    } finally {
      setAcceptIsLoading(false);
    }
  };

  const handleDeclineQuote = async (reasonForDecline: string) => {
    try {
      setDeclineIsLoading(true);
      await authorizedRequest.post(`${API_URL}/quoterequest/markDeclined`, {
        quoteRequestId: quoteRequest?._id,
        clientEmail: quoteRequest?.contactPerson?.email,
        clientName: quoteRequest?.contactPerson?.name,
        reasonForDecline,
      });

      setQuoteRequest((prev: QuoteRequest) => {
        return {
          ...prev,
          quoteStatus: "Declined",
        };
      });

      toast.success("Quote declined successfully");
    } catch (error) {
      toast.error("Error declining quote");
      console.error("Failed to decline quote:", error);
    } finally {
      setDeclineIsLoading(false);
    }
  };

  return quoteRequest?.quoteStatus !== "Pending" ? null : (
    <div className="flex gap-2">
      {acceptIsLoading ? (
        <SyncLoader color="#0068B5" />
      ) : (
        <button
          onClick={() => setAcceptModalIsOpen(true)}
          className="bg-green-400 hover:bg-green-600 text-white p-2 text-lg rounded-md"
        >
          <div className="flex items-center gap-3">
            <span>Accept Quote Request</span>
            <FaCheck />
          </div>
        </button>
      )}
      {declineIsLoading ? (
        <SyncLoader color="#0068B5" />
      ) : (
        <button
          onClick={() => setDeclineModalIsOpen(true)}
          className="bg-red-400 hover:bg-red-600 text-white p-2 text-lg rounded-md"
        >
          <div className="flex items-center gap-2">
            <span>Decline Quote Request</span>
            <TiDelete />
          </div>
        </button>
      )}

      <AcceptModal
        isOpen={acceptModalIsOpen}
        onClose={() => setAcceptModalIsOpen(false)}
        onConfirm={handleAcceptQuote}
      />

      <DeclineModal
        isOpen={declineModalIsOpen}
        onClose={() => setDeclineModalIsOpen(false)}
        onConfirm={(reason: string) => handleDeclineQuote(reason)}
      />
    </div>
  );
};
