import React from "react";
import { JobStatus } from "./JobStatus";
import { JobId } from "./JobId";
import { useJobContext } from "../../../providers/JobProvider";

type HeaderProps = {
  jobId: string;
};

export const Header: React.FC<HeaderProps> = ({ jobId }) => {
  const { job, isLoading } = useJobContext();
  return (
    <div className="flex justify-between items-center">
      <div className="flex justify-start items-center">
        <h1 className="text-3xl font-bold pr-2">Job</h1>
        <JobId jobId={jobId} />
      </div>
      <JobStatus
        isLoading={isLoading}
        jobStatus={job?.status || "Not Started"}
      />
    </div>
  );
};
