import { useState } from "react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { FilterButton } from "../../../components/FilterButton";

type FilterDropdownProps = {
  setFilterStatus: React.Dispatch<React.SetStateAction<string>>;
};

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  setFilterStatus,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const outsideClickRef = useOutsideClick(() => setIsDropdownOpen(false));

  const handleSelect = (status: string) => {
    setFilterStatus(status);
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative" ref={outsideClickRef}>
      <FilterButton buttonText="Filter" setIsDropdownOpen={setIsDropdownOpen} />

      {/* Static Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute z-10 bg-white rounded-lg border border-gray-300 shadow-2xl w-44 top-full translate-y-1">
          <ul className="py-1 text-sm text-gray-700">
            <li>
              <button
                onClick={() => handleSelect("All")}
                className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
              >
                All
              </button>
            </li>
            {["Pending", "Accepted", "Quote Sent", "Declined"].map((status) => (
              <li key={status}>
                <button
                  onClick={() => handleSelect(status)}
                  className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                >
                  {status}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
