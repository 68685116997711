import React, { useState } from "react";
import { Material } from "../../Types/types";
import { FormModal } from "../FormModal";

type MaterialModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setMaterials: React.Dispatch<React.SetStateAction<Material[]>>;
};

export const MaterialModal: React.FC<MaterialModalProps> = ({
  isOpen,
  onClose,
  setMaterials,
}) => {
  const initialFormData: Material = {
    name: "",
    unitPrice: 0,
    quantity: 0,
  };

  const handleSubmit = (data: Material) => {
    setMaterials((prev) => [...prev, data]);
  };

  const formFields = [
    { name: "name", label: "Name", type: "text", placeholder: "Name" },
    {
      name: "unitPrice",
      label: "Unit Price",
      type: "number",
      placeholder: "Unit Price",
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      placeholder: "Quantity",
    },
  ];

  return (
    <FormModal<Material>
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Add Material"
    />
  );
};
