import { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../../components/Pagination/Pagination";
import type { QuoteRequest } from "../../../Types/types";
import { useQuoteRequests } from "../../../hooks/useQuoteRequests";
import { QuoteRequestsTable } from "./QuoteRequestsTable";
import { FilterDropdown } from "./FilterDropdown";
import { ResultsCount } from "../../../components/ResultsCount";
import SearchBar from "../../../components/SearchBar";
import { TabLayout } from "../../../components/TabLayout";

const QUOTE_REQUESTS_PER_PAGE = 10;

const QuoteRequests = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const [filterStatus, setFilterStatus] = useState(location.state || "All");
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useQuoteRequests(
    currentPage,
    QUOTE_REQUESTS_PER_PAGE
  );

  const totalPages = data?.totalPages || 0;

  const filteredQuoteRequests: QuoteRequest[] = useMemo(() => {
    const allQuoteRequests = data?.quoteRequests || [];

    if (!allQuoteRequests) return [];

    let filteredQuoteRequests = allQuoteRequests;

    if (searchTerm) {
      filteredQuoteRequests = allQuoteRequests.filter((quoteRequest) =>
        quoteRequest?.contactPerson?.name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }

    if (filterStatus && filterStatus !== "All") {
      filteredQuoteRequests = allQuoteRequests.filter(
        (quoteRequest) => quoteRequest.quoteStatus === filterStatus
      );
    }

    return filteredQuoteRequests;
  }, [searchTerm, filterStatus, data?.quoteRequests]);

  return (
    <TabLayout title="Quote Requests">
      <div className="flex items-center mb-4">
        <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />

        <FilterDropdown setFilterStatus={setFilterStatus} />
      </div>

      {/* Results Section */}
      <ResultsCount
        results={filteredQuoteRequests.length}
        isLoading={isLoading}
      />

      <QuoteRequestsTable
        quoteRequests={filteredQuoteRequests}
        isLoading={isLoading}
      />

      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
          isLoading={isLoading}
        />
      </div>
    </TabLayout>
  );
};
export default QuoteRequests;
