// @ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { QuoteBuilder } from "../../../components/QuoteBuilder/QuoteBuilder";
import authorizedRequest from "../../../utils/authorizedRequest";
import { ActionButtons } from "./ActionButtons";
import { InfoSection } from "./InfoSection";
import { Divider } from "../../../components/UI/Divider";
import { Header } from "./Header";
import type { QuoteRequest as QuoteRequestType } from "../../../Types/types";
import { SentQuote } from "./SentQuote";
import { PageWrapper } from "../../../components/RecordDetail/PageWrapper";
import { MainContentWrapper } from "../../../components/RecordDetail/MainContentWrapper";

const QuoteRequest: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { quoteRequestId } = useParams();

  const [quoteRequest, setQuoteRequest] = useState<
    QuoteRequestType | undefined
  >(undefined);

  useEffect(() => {
    const fetchQuote = async () => {
      if (quoteRequestId) {
        try {
          const quote = await authorizedRequest<Quote>(
            `${API_URL}/quoterequest/${quoteRequestId}`
          );
          console.log("QUOTE:", quote);
          setQuoteRequest(quote.data);
        } catch (error) {
          console.error("Error fetching quote:", error);
        }
      } else {
        console.log("Quote ID not found.");
        setQuoteRequest(defaultQuote);
      }
    };

    fetchQuote();
  }, [quoteRequestId, API_URL]);

  return (
    <PageWrapper>
      <Header quoteRequest={quoteRequest} />

      <MainContentWrapper
        backButtonLabel="Quote Requests"
        backButtonLink="/admin/quote-requests"
      >
        <div className="flex justify-between pb-4">
          <InfoSection quoteRequest={quoteRequest} />
          <div>
            <ActionButtons
              quoteRequest={quoteRequest}
              setQuoteRequest={setQuoteRequest}
            />
          </div>
        </div>
        {quoteRequest?.quoteStatus === "Accepted" ? (
          <>
            <Divider />
            <div className="pt-4">
              <QuoteBuilder
                quoteRequest={quoteRequest}
                setQuoteRequest={setQuoteRequest}
              />
            </div>
          </>
        ) : (
          quoteRequest?.quoteStatus === "Quote Sent" && (
            <>
              <Divider />
              <div className="pt-4">
                <SentQuote quoteRequestId={quoteRequestId} />
              </div>
            </>
          )
        )}
      </MainContentWrapper>
    </PageWrapper>
  );
};

export default QuoteRequest;
