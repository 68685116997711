import React from "react";

type TableHeaderProps = {
  title: string;
  className?: string; // Classes must be passed in as important (ex: instead of text-xl, use !text-xl)
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  title,
  className = "",
}) => {
  const headerClassName = `${className} px-4 py-2 border-b border-gray-300 text-left text-xs font-semibold tracking-wider`;

  return <th className={headerClassName}>{title}</th>;
};
