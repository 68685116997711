import React, { useState } from "react";
import { ModalLayout } from "./ModalLayout";
import { SyncLoader } from "react-spinners";
import { useOutsideClick } from "../hooks/useOutsideClick";

type FormField<T> = {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  options?: Array<{ value: string | number; label: string }>;
};

type FormModalProps<T> = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: T) => void;
  initialFormData: T;
  formFields: FormField<T>[];
  title: string;
  isLoading?: boolean;
};

export const FormModal = <T,>({
  isOpen,
  onClose,
  onSubmit,
  initialFormData,
  formFields,
  title,
  isLoading = false,
}: FormModalProps<T>) => {
  const [formData, setFormData] = useState<T>(initialFormData);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData(initialFormData);
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return !isOpen ? null : (
    <ModalLayout onOutsideClick={onClose}>
      <h2 className="text-3xl font-bold pb-4">{title}</h2>
      <form onSubmit={handleFormSubmit}>
        <div
          className={`grid ${
            formFields?.length > 3 ? "grid-cols-2" : ""
          } gap-4 pb-4`}
        >
          {formFields.map((field) => (
            <React.Fragment key={field.name}>
              {field.type === "select" && field?.options ? (
                <div className="flex flex-col">
                  <label>{field.label}</label>

                  <select
                    name={field.name}
                    onChange={handleInputChange as any}
                    required
                    className="border border-gray-800 rounded-md p-2 shadow-lg"
                  >
                    <option value="" disabled>
                      Select {field.label}
                    </option>
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="flex flex-col">
                  <label>{field.label}</label>

                  <input
                    type={field.type}
                    name={field.name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-800 rounded-md p-2 shadow-lg"
                    placeholder={field?.placeholder || ""}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex justify-end gap-2">
          <button
            type="button"
            onClick={onClose}
            className=" bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded transition duration-150 ease-in-out"
          >
            Cancel
          </button>
          {isLoading ? (
            <SyncLoader color="#0068B5" />
          ) : (
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded transition duration-150 ease-in-out"
            >
              Add
            </button>
          )}
        </div>
      </form>
    </ModalLayout>
  );
};
