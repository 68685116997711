import React, { useState } from "react";
import { Materials } from "./Materials";
import type { Material } from "../../Types/types";
import type { Labor as LaborType } from "../../Types/types";
import type { QuoteInformation as QuoteInformationType } from "../../Types/types";
import { Labor } from "./Labor";
import QuotePreview from "./QuotePreview";
import { InformationForm } from "./InformationForm";
import { BiMailSend } from "react-icons/bi";
import { SyncLoader } from "react-spinners";
import ReactDOMServer from "react-dom/server";
import authorizedRequest from "../../utils/authorizedRequest";
import type { QuoteRequest } from "../../Types/types";
import { toast } from "react-toastify";
import { getCosts } from "../../utils/getCosts";

type QuoteBuilderProps = {
  quoteRequest: QuoteRequest;
  setQuoteRequest: React.Dispatch<React.SetStateAction<QuoteRequest>>;
};

export const QuoteBuilder: React.FC<QuoteBuilderProps> = ({
  quoteRequest,
  setQuoteRequest,
}) => {
  const [sendQuoteIsLoading, setSendQuoteIsLoading] = useState(false);
  const [materials, setMaterials] = useState<Material[]>([]);
  const [labor, setLabor] = useState<LaborType[]>([]);
  const [quoteInformation, setQuoteInformation] =
    useState<QuoteInformationType | null>(null);

  const costs = getCosts(materials, labor);

  const generateHtmlWithStyles = (htmlContent: string) => {
    return `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <!-- Include the Tailwind CSS -->
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
          <title>Quote PDF</title>
        </head>
        <body class="bg-gray-100 text-gray-900">
          ${htmlContent}
        </body>
      </html>
    `;
  };

  const handleSendQuote = async () => {
    setSendQuoteIsLoading(true);

    const clientName = quoteInformation?.clientName;
    const projectName = quoteInformation?.projectName;
    const userEmail = quoteRequest?.contactPerson?.email;

    try {
      const quoteResponse = await authorizedRequest.post(`/quotes/createNew`, {
        materials,
        labor,
        clientName,
        projectName,
        quoteRequestId: quoteRequest?._id,
      });

      const newQuote = quoteResponse?.data?.quote;

      const quoteHTML = ReactDOMServer.renderToString(
        <QuotePreview
          materials={materials}
          labor={labor}
          quoteId={newQuote?.quoteId}
          quoteInformation={quoteInformation}
        />
      );

      const quoteWithStyles = generateHtmlWithStyles(quoteHTML);

      await authorizedRequest.post(`/quotes/sendQuote`, {
        materials,
        labor,
        clientName,
        projectName,
        quoteRequestId: quoteRequest?._id,
        userEmail,
        quote: newQuote,
        quoteHTML: quoteWithStyles,
      });

      setQuoteRequest((prev: QuoteRequest) => {
        return {
          ...prev,
          quoteStatus: "Quote Sent",
        };
      });

      toast.success("Quote sent successfully");
    } catch (error) {
      toast.error("Failed to send quote");
      console.error(error);
    } finally {
      setSendQuoteIsLoading(false);
    }
  };

  return (
    <>
      <h1 className="text-3xl pb-4">Quote Builder</h1>
      <div className="flex flex-col md:gap-11 md:flex-row">
        <QuotePreview
          materials={materials}
          labor={labor}
          quoteInformation={quoteInformation}
        />
        <div>
          <div className="pb-5">
            <InformationForm
              quoteInformation={quoteInformation}
              setQuoteInformation={setQuoteInformation}
            />
          </div>
          <div className="pb-10">
            <Materials materials={materials} setMaterials={setMaterials} />
          </div>
          <div className="pb-5">
            <Labor labor={labor} setLabor={setLabor} />
          </div>
          <div>
            <h1 className="text-2xl pb-2">{`Subtotal: $${costs.totalCostBeforeTax}`}</h1>
            <h1 className="text-2xl pb-2">{`Tax: $${costs.taxCost}`}</h1>
            <h1 className="text-2xl pb-2">{`Total: $${costs.totalCostAfterTax}`}</h1>
          </div>

          <div className="flex justify-end">
            {sendQuoteIsLoading ? (
              <SyncLoader color="#0068B5" />
            ) : (
              <button
                onClick={handleSendQuote}
                className="bg-green-400 hover:bg-green-600 text-white p-2 text-lg rounded-md"
              >
                <div className="flex items-center gap-3">
                  <span>Send Quote</span>
                  <BiMailSend />
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
