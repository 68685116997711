import { useQuery, UseQueryResult } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import type { Job } from "../../../Types/types";

type UseJobResult = {
  data: Job | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
};

// Fetches an individual job
export const useFetchJob = (jobId: string): UseJobResult => {
  const API_URL = process.env.REACT_APP_API_URL as string;

  const { data, isLoading, isError, error, refetch }: UseQueryResult<Job> =
    useQuery<Job>({
      queryKey: ["job", jobId],
      queryFn: async (): Promise<Job> => {
        const response: any = await authorizedRequest(
          `${API_URL}/jobs/${jobId}`
        );
        return response?.data as Job;
      },
      refetchInterval: 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!jobId,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
