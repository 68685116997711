import React, { useState } from "react";
import { FilterButton } from "../../../components/FilterButton";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { TAGS } from "../../../Types/tagsType";

type SearchTagsProps = {
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SearchTags: React.FC<SearchTagsProps> = ({
  selectedTags,
  setSelectedTags,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelect = (tag: string) => {
    setSelectedTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  const outsideClickRef = useOutsideClick(() => setIsDropdownOpen(false));

  return (
    <div className="relative" ref={outsideClickRef}>
      <FilterButton
        buttonText="Search Tags"
        setIsDropdownOpen={setIsDropdownOpen}
      />

      {isDropdownOpen && (
        <div className="absolute z-10 bg-white rounded-lg border border-gray-300 shadow-2xl w-44 top-full translate-y-1">
          <ul className="py-1 text-sm text-gray-700">
            <li>
              <button
                onClick={() => handleSelect("All")}
                className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
              >
                All
              </button>
            </li>
            {TAGS.map((tag) => (
              <li key={tag}>
                <button
                  onClick={() => handleSelect(tag)}
                  className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                >
                  {tag}{" "}
                  {selectedTags.includes(tag) && (
                    <span className="absolute right-4 text-gray-500">✓</span>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
