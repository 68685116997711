import { SyncLoader } from "react-spinners";

export const LoginRedirect = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-[60vh] bg-gray-50">
      <h1 className="text-3xl font-bold text-gray-700 mb-4">Redirecting...</h1>
      <p className="text-gray-500 mb-8">
        Please wait while we take you to the dashboard.
      </p>
      <SyncLoader className="pr-2" color="#0068B5" />
    </div>
  );
};
